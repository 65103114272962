import { checkEmailExists } from '@/services/validations-helper';

import {
    required,
    email
} from 'vuelidate/lib/validators';

export const validationRules = {
    email: {
        required,
        email: (val) => email(val.toLowerCase()),
        async emailExists(value) {
            // ignore this validator, if any of the previous fail
            if (!this.$v.email.required || !this.$v.email.email) { return true; }

            try {
                await checkEmailExists(value)
                return true
            } catch (error) {
                return false
            }
        },
        validateEmail(value) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(value).toLowerCase());
        }
    }
}
